$(document).ready(function () {
    const $logoList = $('.xxx');
    const $logos = $logoList.find('li');
    const logosArray = $logos.toArray();
    let logosToShow = 5;
    const intervalTime = 4000; // 4 Sekunden
    let interval;
  
    function updateLogosToShow() {
      const width = $(window).width();
      if (width < 600) {
        logosToShow = 3;
      } else if (width < 1024) {
        logosToShow = 4;
      } else {
        logosToShow = 5;
      }
    }
  
    function shuffleAndShow() {
      // Alle Logos verstecken
      $logos.hide();
  
      // Array mischen
      const shuffled = logosArray.sort(() => 0.5 - Math.random());
      const selected = shuffled.slice(0, logosToShow);
  
      // Gewählte einblenden
      $(selected).fadeIn(600);
    }
  
    function swapOneLogo() {
      const visible = $logos.filter(':visible');
      const hidden = $logos.filter(':hidden');
  
      if (hidden.length === 0) return; // nichts zu tauschen
  
      const randomVisible = $(visible.get(Math.floor(Math.random() * visible.length)));
      const randomHidden = $(hidden.get(Math.floor(Math.random() * hidden.length)));
  
      randomVisible.fadeOut(400, function () {
        randomHidden.fadeIn(400);
      });
    }
  
    function startShuffle() {
      clearInterval(interval);
      interval = setInterval(() => {
        swapOneLogo();
      }, intervalTime);
    }
  
    // Initial
    updateLogosToShow();
    shuffleAndShow();
    startShuffle();
  
    // Wechsel pausieren bei Hover
    $logoList.hover(
      function () {
        clearInterval(interval);
      },
      function () {
        startShuffle();
      }
    );
  
    // Reagieren auf Fenstergröße
    $(window).on('resize', function () {
      updateLogosToShow();
      shuffleAndShow();
      startShuffle();
    });
  });
  